import { ref } from 'vue';

const API_MAPPING = {
    'amsterdam-west': 'boat-and-co',
    'amsterdam-south': 'twenty-eight'
};

interface RatingResponse {
    averageRating: number;
    totalRatings: number;
}

interface ReviewsComposable {
    averageRating: number;
    totalElements: number;
    getAverageRating: (slug: keyof typeof API_MAPPING) => Promise<Object>;
    getLatestReviews: (slug: keyof typeof API_MAPPING) => Promise<any[]>;
    getAllLatestReviews: () => Promise<any[]>;
}

export default function useReviews(): ReviewsComposable {
    const averageRating = ref(0);
    const totalElements = ref(0);

    const getAverageRating = async(slug: keyof typeof API_MAPPING): Promise<Object> => {
        if (Object.keys(API_MAPPING).indexOf(slug) !== -1) {
            let rating: RatingResponse;
            try {
                rating = await $fetch('/api/rating/' + API_MAPPING[slug]);
                averageRating.value = rating?.averageRating;
                totalElements.value = rating?.totalRatings;

                return { averageRating, totalElements };
            } catch (e) {
                // Fail silently
                return {};
            }
        }

        return {};
    };

    const getLatestReviews = async(slug: keyof typeof API_MAPPING): Promise<any[]> => {
        if (Object.keys(API_MAPPING).indexOf(slug) !== -1) {
            let reviews: any[];
            try {
                reviews = await $fetch('/api/reviews/' + API_MAPPING[slug]);
                return reviews;
            } catch (e) {
                // Fail silently
                return [];
            }
        }

        return [];
    };

    const getAllLatestReviews = async() => {
        const reviews: any[] = [];
        // eslint-disable-next-line
        for (const slug in API_MAPPING) {
            const review = await getLatestReviews(slug as keyof typeof API_MAPPING);
            reviews.push(...review);
        }

        return reviews.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
    };

    return { 
        averageRating: averageRating.value,
        totalElements: totalElements.value,
        getAverageRating,
        getLatestReviews,
        getAllLatestReviews
    };
}
